import React, { Component } from 'react';
export class MenuLink extends Component {

  onSelect = () => {
    this.props.onSelect(this.props.index);
  }

  render() {
      return <li className={this.props.active?'active':''} onClick={this.onSelect}>
        <a href={`#${this.props.title.toLowerCase()}`}>{this.props.title}</a>
      </li>
  }
}

export class Menu extends Component {

    state = {
        links : ['About','Experience', 'Contact'],
        activeIndex: 0
    }
    
    onSelect = (index) => {
        this.setState({
            activeIndex : index
        })
    }

    render() {
        
        return this.state.links.map( (link,index) => {
            return <MenuLink onSelect={this.onSelect} index={index} title={link} active={this.state.activeIndex===index}></MenuLink>
        })
    }
}