import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReact, faAngular, faJs, faNodeJs, faSass, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

import {MenuLink} from './MenuLink';
import {Menu} from './MenuLink';

library.add(faReact)

class App extends Component {

  render() {
    return (
      <div className="App">
        <div className="SideBar">
        <div id="SideBarLogo" className="sidebar-logo">
          <h2>Steve Perry</h2>
          <span>SENIOR JAVASCRIPT DEVELOPER</span>
        </div>
        <ul id="SideBarMenu">
          <Menu></Menu>
        </ul>
        <div className="square">
          <p>Currently working at</p>
          <div className="currently">
          <img src="tr-logo.svg" /></div>
        </div>
        <div>
          {/* <div className="sash"></div>
          <div className="sash"></div> */}
        </div>
        </div>
        <div className="content-container">
        <div className="Content">
          
          <section className="name" id="about">
            <h1>Steve Perry</h1>
            <h2>Senior Javascript Developer</h2>
            <h3>| London</h3>
            
            <ul className="icons">
              <li><FontAwesomeIcon icon={faReact} /><span>REACT</span></li>
              <li><FontAwesomeIcon icon={faAngular} /><span>ANGULAR</span></li>
              <li><img src="javascript-js.svg" width="60"/><span>JAVASCRIPT</span></li>
              <li><FontAwesomeIcon icon={faNodeJs} /><span>NODE</span></li>
              {/* <li><FontAwesomeIcon icon={faSass} /><span>SASS</span></li> */}
              <li><img src="redux.svg" width="60"/><span>REDUX</span></li>
              <li><img src="graphql-logo.svg" width="60"/><span>GRAPHQL</span></li>
            </ul>
            
            <p>Twelve years of professional experience in front end development, specialising in Javascript primarily using AngularJS and React.</p>
            <p>I have strong experience developing production ready applications as part of teams using Agile Scrum and Kanban methodologies, along with experience as a team lead and manager.</p>
            <p>A self-motivated and proactive developer with excellent communication skills, always looking toimprove and open to working with new technologies</p>

          </section>

          {/* <section>
            Latest Project Stack : <FontAwesomeIcon icon={faReact} /><img src="graphql-logo.svg" width="50" />
          </section>
          <hr/> */}
          <section id="experience">
          <h2>Experience</h2>

          <aside>
          <div className="logo">
            <img src="travel_republic_logo.svg" />
            <div><h4>Senior Javascript Developer</h4>
            <span className="dates">JULY 2016 - NOV 2018</span></div>
          </div>
          {/* <div className="dates"><span>2016-</span></div> */}
            <ul className="points">
              <li>Developed new core features and functionality for the AngularJS web application</li>
              <li>Built custom React components for use with content management system</li>
              <li>Maintenance of several websites with multi language support and integration with .NET backend</li>
              <li>Worked as part of highly productive distributed team, using Agile Scrum methodology</li>
              <li>Produced applications using React and GraphQL and Node JS and MongoDB for internal tooling</li>
              <li>Integrated third party APIs for analytics and tracking plugins</li>
              <li>Responsible for development and overseeing the rollout of significant updates made to key partsof the customer booking journey simultaneously across multiple brands, implementing customvalidation and A/B testing setup</li>
            </ul>

            
          </aside>
          
          <aside>
            <div className="logo virgin">
                <img src="Virgin_Media.svg" />
                <div><h4>Senior Multiscreen Developer</h4>
                <span className="dates">SEPT 2013 - JULY 2016</span></div>
            </div>
            {/* <div className="dates"><span>2013-2016</span></div> */}
            <ul className="points">
              <li>Developed interactive Connected TV applications for the TiVo Platform using AngularJS</li>
              <li>Worked in an Agile Scrum team to deliver an custom online video playback experience for the ‘TV Anywhere’ live and on demand video streaming service</li>
              <li>Involved with hiring and interview process of team members and managing workflow for freelance resource</li>
              <li>Led knowledge sharing sessions and ‘hackdays’ with other team members to improve collaboration and communication</li>
            </ul>
          </aside>

          <aside>
            <div className="logo virgin">
              <img src="Virgin_Media_UK.png" />
              <div><h4>Senior Web Developer</h4>
              <span className="dates">OCT 2010 - SEPT 2013</span></div>
            </div>
            {/* <div className="dates"><span>2010-2013</span></div> */}
            <ul className="points">
              <li>Lead development contact and SME for implementations of TV applications both within the business and with third party providers, representing the business at external client meetings.</li>
              <li>Built Javascript TV applications for major channel brands such as Sky, Cartoon Network, ESPN and
              National Geographic</li>
              <li>Designed and implemented a custom JIRA workflow and component framework for application
              development delivery</li>
            </ul>
          </aside>
          <aside>
            <div className="logo virgin">
                <img src="Virgin_Media_Spark.png" />
                <div><h4>Flash Developer</h4>
              <span className="dates">SEPT 2008 - OCT 2010</span></div>
            </div>
            {/* <div className="dates"><span>2008-2010</span></div> */}
            <ul className="points">
              <li>Development and implementation of many interactive rich media advertising campaigns launched across the virginmedia.com website</li>						
              <li>Responsible for the implementation of video playback product across the site, building custom video players for use with the Brightcove platform</li>
              <li>Developed and integrated custom advertising and monitoring plugins to suitbusiness requirements</li>
            </ul>
          </aside>

          <aside>
            <div className="logo virgin">
              <img src="Virgin_Media_Spark.png" />
              <div><h4>Designer / Developer</h4>
              <span className="dates">JULY 2005 - SEPT 2008</span></div>
            </div>
            {/* <div className="dates"><span>2005-2008</span></div> */}
            <ul className="points">
              <li>Provided flash creative used in marketing campaigns for large corporate brands such as Barclays and Npower.</li>
              <li>Developed websites using HTML, CSS and Javascript as part of a distributed team</li>
              <li>Produced design and artwork of brochures, leaflets and other promotional materials for clients</li>
            </ul>
          </aside>
          </section>

          <section className="contact" id="contact">

            <h2>Contact</h2>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /><span><a href="mailto:steve@experryment.com">steve@experryment.com</a></span>
            </p>
            <p>
              <FontAwesomeIcon icon={faLinkedin} /><span><a href="https://www.linkedin.com/in/steveperry124/">linkedin/steveperry124</a></span>
            </p>

          </section>

          </div>
          
          
        </div>
      </div>
    );
  }
}

export default App;
